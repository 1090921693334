export default {
  methods: {
    async isImage(url) {
      if (url.match(/\.(jpeg|jpg|gif|png|webp)$/) != null) {
        return true;
      }

      const doesImageExist = (url) =>
        new Promise((resolve) => {
          const img = new Image();

          if (url === undefined) {
            resolve(false)
          } else {
            img.src = url;
            img.onload = () => resolve(true);
            img.onerror = () => resolve(false);
          }
        });
      return await doesImageExist();
    },
    isVideo(url) {
      return url.match(/\.(mp4|avi)$/) != null;
    },
  },
};
