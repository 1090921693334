export default {
  props: {
    name: { type: String, default: '' },
    hideError: { type: Boolean, default: false },
    validations: { type: Array, default: () => [] },
    validate: { type: [Number, String, Boolean], default: false },
  },

  data() {
    return {
      isRequired:
        this.validations.filter((rule) => {
          if (typeof rule() === 'function') {
            let innerRule = rule('');
            return innerRule('').toString().includes("shouldn't be empty.");
          } else {
            return rule('').toString().includes("shouldn't be empty.");
          }
        }).length > 0,
      error: ' ',
      allValidationsPassed: this.validations.length === 0 || !this.validate,
    };
  },

  watch: {
    // innerVal() {
    //   this.validateInput();
    // },
    validate(value, oldVal) {
      if (value !== oldVal) {
        this.validateInput();
      }
    },
    focus(value) {
      if (!value) {
        this.validateInput();
      }
    },
  },

  methods: {
    validateInput() {
      let error = this.hasError(this.value);
      this.error = error || '';
      this.allValidationsPassed = error || true;
    },
    hasError(value) {
      const rules = this.validations;

      for (let i = 0; i < rules.length; i++) {
        const rule = rules[i];
        let validate = false;
        if (typeof rule() === 'function') {
          let innerRule = rule();
          validate = this.name ? innerRule(value, this.name) : innerRule(value);
        } else {
          validate = this.name ? rule(value, this.name) : rule(value);
        }
        if (validate !== true) {
          return validate;
        }
      }
      return false;
    },
  },
};
