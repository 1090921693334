<template>
  <div class="card shadow-40 p-0" :class="{ hoverable }" v-on="$listeners">
    <div
      v-if="header"
      class="
        p-2
        text-body-2
        bg-gray-dark-4
        text-white text-uppercase
        font-style-secondary
      "
    >
      {{ header }}
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'AppCard',

  props: {
    header: { type: String, default: '' },
    hoverable: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/theme/_colors';
@import '@/scss/theme/_shadows';
.hoverable {
  transition: background-color 0.12s ease-in-out, box-shadow 0.12s ease-in-out;
  &:hover {
    cursor: pointer;
    @extend .bg-gray-light-2;
    @extend .shadow-100;
  }
}
</style>
