<template>
  <AppDrawer v-model="innerVal" right :width="570">
    <div class="d-flex flex-column h-100">
      <!-- Header -->
      <div
        class="
          p-3
          d-flex
          align-items-center
          justify-content-between
          bg-gray-light-4
        "
      >
        <h4>ATTACHED FILE</h4>
        <AppIcon name="close" @click="innerVal = false"></AppIcon>
      </div>
      <!-- Content -->
      <div class="h-100 overflow-auto">
        <div
          v-for="(file, index) in files"
          :key="`file-attachment-${index}`"
          class="p-5"
        >
          <AppMediaViewer
            :src="file.filename"
            width="100%"
            aspectRatio="1/1"
          ></AppMediaViewer>
        </div>
      </div>
    </div>
  </AppDrawer>
</template>

<script>
import AppDrawer from '../../../shared/elements/AppDrawer.vue';
import AppIcon from '../../../shared/elements/AppIcon.vue';
import _appDefaultInput from '@/shared/mixins/_appDefaultInput';
import AppMediaViewer from '../../../shared/elements/AppMediaViewer.vue';

export default {
  name: 'AttachedFileDrawer',

  components: { AppDrawer, AppIcon, AppMediaViewer },

  mixins: [_appDefaultInput],

  props: {
    files: { type: Array, default: () => [] },
  },
};
</script>

<style lang="scss" scoped></style>
