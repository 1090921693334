<template>
  <div
    :class="[`bg-${color}`, vertical ? 'h-100' : 'w-100']"
    :style="`${vertical ? 'width:' : 'height:'} ${
      isNaN(size) ? size : `${size}px`
    }`"
  ></div>
</template>

<script>
export default {
  name: 'AppDivider',

  props: {
    vertical: { type: Boolean, default: false },
    color: { type: String, default: 'gray-light-2' },
    size: { type: [String, Number], default: 1 },
  },
};
</script>

<style lang="scss" scoped></style>
