import axios from 'axios';
import { URL_PREFIX } from './variables';

export const getCart = (...rest) =>
    axios.get(`${URL_PREFIX}/cart?status=active`, ...rest);
export const postCart = (...rest) =>
    axios.post(`${URL_PREFIX}/cart`, ...rest);
export const updateCart = (id, ...rest) =>
    axios.post(`${URL_PREFIX}/cart/${id}?_method=PUT`, ...rest);

export const deleteItem = (id, ...rest) =>
    axios.delete(`${URL_PREFIX}/cart/${id}`, ...rest);

export default {
    getCart,
    postCart,
    updateCart,
    deleteItem,
};
