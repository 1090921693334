<template>
  <div>
    <p class="go-back-btn d-flex align-items-center mr-3" @click="alert ? confirm() : goBack()">
      <AppIcon v-if="icon" :name="icon" :size="size"></AppIcon>
      <span
        class="ml-2 d-none d-md-block"
        :class="[persistText ? 'd-block' : 'd-none']"
      >
        <slot></slot>
      </span>
    </p>
    <ActionModal
      v-model="confirmRedirect"
      persistConfirm
      header="All your data will be lost. Do you want to go back?"
      description="Click confirm to proceed"
      @confirm="goBack"
    ></ActionModal>
  </div>
</template>

<script>
import AppIcon from '@/shared/elements/AppIcon.vue';
import ActionModal from '@/components/Partial/Modals/ActionModal.vue';

export default {
  name: 'BackToBtn',

  components: { AppIcon, ActionModal },

  props: {
    icon: { type: [String, Boolean], default: 'arrow_left' },
    persistText: { type: Boolean, default: false },
    url: { default: null },
    size: { type: [String, Number], default: 24 },
    alert: { type: Boolean, default: false },
  },
  data() {
    return {
      confirmRedirect: false
    }
  },
  methods: {
    confirm() {
      this.confirmRedirect = true;
    },
    goBack() {
      if (!window) {
        return;
      }

      if (this.url) {
        this.$router.push(this.url);
      } else if (window.history.length > 1) {
        this.$router.back();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.go-back-btn {
  cursor: pointer;
}
</style>
