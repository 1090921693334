<template>
  <div class="data-with-label" v-on="$listeners">
    <span
      class="m-0 text-caption text-uppercase text-truncate d-block"
      :class="[`text-${labelColor}`]"
    >
      {{ label }}
    </span>
    <slot>
      <p class="text-body-2 font-weight-5">
        <slot name="text">
          {{ text }}
        </slot>
      </p>
    </slot>
  </div>
</template>
<script>
export default {
  name: 'DashboardWithLabel',

  props: {
    label: { type: String, default: '' },
    text: { type: String, default: '' },
    labelColor: { type: String, defaul: 'gray-dark-3' },
  },
};
</script>

<style lang="scss" scoped></style>
