<template>
  <LayoutBase
    :routes="routes"
    :hideTopBar="hideTopBar"
    :sidebarActiveItem="sidebarActiveItem"
  >
    <!-- pass through normal slots -->
    <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
      <slot :name="slotName" />
    </template>

    <template v-slot:topbar-prepend-icons>
      <div class="cart-main">
        <div v-if="totalCart > 0" class="cart-main-notify">
          <div
            class="
              cart-main-notify-icon
              rounded-circle
              bg-success
              text-white text-center
            "
          >
            {{ totalCart }}
          </div>
        </div>

        <AppIcon
          hoverable
          name="shopping_cart"
          class="m-2 ml-3 mr-md-5"
          @click="showCart = true"
        >
        </AppIcon>
      </div>
      <CartDrawer v-model="showCart"></CartDrawer>
    </template>
  </LayoutBase>
</template>

<script>
import LayoutBase from '../LayoutBase.vue';
import Notifications from '@/shared/api/Notifications';
import AppIcon from '@/shared/elements/AppIcon.vue';
import CartDrawer from '../../Cart/CartDrawer.vue';
import { mapGetters } from 'vuex';

export default {
  components: { LayoutBase, AppIcon, CartDrawer },
  props: {
    sidebarActiveItem: { default: false },
    hideTopBar: { type: Boolean, default: false },
  },
  data() {
    return {
      notifications: {
        order: {},
      },
      showCart: false,
    };
  },

  computed: {
    ...mapGetters('cart', ['active_cart']),
    totalCart() {
      return this.active_cart.reduce(
        (total, cart) => total + cart.items.length,
        0
      );
    },
    routes() {
      return [
        {
          route: '/analytics',
          exact: false,
          text: 'Stats',
          icon: 'analytics',
          notifications: 0,
          activeName: 'dashboard',
        },
        {
          route: '/product',
          exact: false,
          text: 'Materials & Supplies',
          icon: 'shopping_catalog',
          activeName: 'dashboard',
          allowedGroups: null,
          notifications: 0,
        },
        {
          route: '/category',
          exact: false,
          text: 'Manage Product Category',
          icon: 'settings',
          notifications: 0,
          activeName: 'category',
          allowedGroups: null,
        },
        {
          route: '/order',
          exact: false,
          text: 'Orders',
          icon: 'catalog',
          activeName: 'order',
          notifications: 0,
          subRoutes: [
            {
              route: '/request',
              overrideParentRoute: '/order',
              text: 'Pending RFQs',
              exact: true,
              activeName: 'request',
            },
            {
              route: '/approved',
              overrideParentRoute: '/order',
              text: 'Approved RFQs',
              exact: true,
              activeName: 'approved',
            },
            {
              route: '/quoted',
              overrideParentRoute: '/order',
              text: 'Pending Orders',
              exact: true,
              activeName: 'quoted',
            },
            {
              route: '/accepted',
              overrideParentRoute: '/order',
              text: 'Accepted Orders',
              exact: true,
              activeName: 'accepted',
            },
            {
              route: '/completed',
              overrideParentRoute: '/order',
              text: 'Completed Orders',
              exact: true,
              activeName: 'completed',
            },
            {
              route: '/rejected',
              overrideParentRoute: '/order',
              text: 'Rejected RFQs',
              exact: true,
              activeName: 'rejected',
            },
            // {
            //   route: '/rejected',
            //   overrideParentRoute: '/quote',
            //   text: 'Rejected Orders',
            //   exact: true,
            //   activeName: 'rejected',
            // },
          ],
        },
        {
          route: '/company',
          exact: false,
          text: 'User Manager',
          icon: 'group',
          notifications: 0,
          allowedGroups: null,
          activeName: 'company',
          subRoutes: [
            {
              route: '/vendor',
              text: 'Vendor',
              activeName: 'vendor',
              exact: false,
            },
            {
              route: '/buyer',
              text: 'Buyer',
              activeName: 'buyer',
              exact: false,
            },
          ],
        },
        {
          route: '/emails',
          exact: false,
          text: 'Emails',
          icon: 'email',
          activeName: 'email',
          notifications: 0,
        },
        {
          route: '/favorites',
          exact: false,
          text: 'Favorites',
          icon: 'favorite',
          notifications: 0,
          activeName: 'favorites',
        },
      ];
    },
  },
  methods: {
    async fetchNotifications() {
      const response = await Notifications.getNotifications();
      this.notifications.dashboard = response.data;
      this.notifications.order = response.data;
    },
  },

  mounted() {
    this.fetchNotifications();
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/custom/_layout';
.cart-main {
  position: relative;
  &-notify {
    position: absolute;
    right: 24px;

    &-icon {
      font-size: 0.8em;
      margin: 2px;
      height: 24px;
      width: 24px;
    }
  }
}
</style>
