<template>
  <AppModal v-model="innerVal" width="550px">
    <div
      class="p-4 d-flex flex-column align-items-center justify-content-center"
    >
      <slot>
        <!-- Content -->
        <slot name="content">
          <AppIcon
            name="checkmark_filled"
            size="70"
            class="text-success"
          ></AppIcon>
          <h5 class="mt-2">
            <slot name="title">Success!</slot>
          </h5>
          <p class="mt-2" v-if="withDescription">
            <slot name="description">Click "confirm" to proceed</slot>
          </p>
        </slot>
        <!-- Action Buttons -->
        <slot name="action-btn">
          <!-- Confirm Action Buttons -->
          <AppBtn class="d-block" @click="innerVal = false">
            <slot name="action-btn-text">Confirm</slot>
          </AppBtn>
        </slot>
      </slot>
    </div>
  </AppModal>
</template>

<script>
import _appDefaultInput from '@/shared/mixins/_appDefaultInput';
import AppModal from '@/shared/elements/AppModal.vue';
import AppIcon from '@/shared/elements/AppIcon.vue';
import AppBtn from '@/shared/elements/AppBtn.vue';

export default {
  name: 'ActionModal',

  components: { AppModal, AppIcon, AppBtn },

  mixins: [_appDefaultInput],

  props: {
    withDescription: { type: [Boolean], default: true },
  },

  watch: {
    innerVal(val) {
      if (!val) {
        this.$emit('confirm', val);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
