import { DateTime } from 'luxon';

export default {
  filters: {
    readableDate(date, noYear = true, parseFormat = false) {
      if (!date) {
        return '';
        // return DateTime.now().toFormat(`dd LLL ${noYear ? '' : 'y'}`);
      } else if (parseFormat === true) {
        return DateTime.fromFormat(date, 'yyyy-MM-dd HH:mm:ss').toFormat(
          `dd LLL ${noYear ? '' : 'y'}`
        );
      } else if (parseFormat) {
        return DateTime.fromFormat(date, parseFormat).toFormat(
          `dd LLL ${noYear ? '' : 'y'}`
        );
      } else {
        return DateTime.fromISO(date).toFormat(`dd LLL ${noYear ? '' : 'y'}`);
      }
    },
    readableTime(date, parseFormat = false) {
      if (!date) {
        return '';
        // return DateTime.now().toFormat(`HH:mm a`);
      } else if (parseFormat === true) {
        return DateTime.fromFormat(date, 'yyyy-MM-dd HH:mm:ss').toFormat(
          `HH:mm a`
        );
      } else if (parseFormat) {
        return DateTime.fromFormat(date, parseFormat).toFormat(`HH:mm a`);
      } else {
        return DateTime.fromISO(date).toFormat(`HH:mm a`);
      }
    },
  },
};
