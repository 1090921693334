<template>
  <AppDrawer right v-model="innerVal">
    <div class="cart-drawer">
      <h4 class="cart-drawer-header p-2 d-flex font-weight-4 bg-gray-light-4">
        <span>CART</span>
        <AppIcon
          hoverable
          name="close"
          class="ml-auto"
          @click="innerVal = false"
        ></AppIcon>
      </h4>

      <div class="cart-drawer-content">
        <!-- Projects -->
        <template v-if="active_cart.length > 0">
          <AppCard
          hoverable
          v-for="(project, index) in active_cart"
          :key="`project-list-${index}`"
          class="pl-2 pr-2"
        >
          <div
            class="d-flex align-items-center pt-4"
            @click.self="checkoutProject(project)"
          >
            <!-- Project Thumbnail -->
            <div class="d-flex align-items-center" @click="checkoutProject(project)">

              <AppIcon
                class="checkout-icon"
                name="shopping_cart"
                size="50"
              />

              <div class="text-center">
                <span class="ml-2 mb-0 font-weight-bold">CHECKOUT</span>
              </div>
            </div>

            <div class="ml-3" @click="checkoutProject(project)">
              <!-- Project Name -->
              <p class="m-0">{{ project.project_name }}</p>
              <!-- Project Items -->
              <p class="m-0 text-caption text-gray">
                {{ project.items.length }}
                {{ `Item${project.items.length > 1 ? 's' : ''}` }}
              </p>
            </div>
            <AppDropdown right class="ml-auto" v-if="true === false">
              <template v-slot:dropdown-button>
                <AppIcon hoverable name="ellipsis"></AppIcon>
              </template>
              <template v-slot:dropdown-menu>
                <div
                  class="app-dropdown-item dropdown-item p-2"
                  @click="duplicateProject"
                >
                  Duplicate Item
                </div>
                <div class="app-dropdown-item dropdown-item p-2">
                  Move to Group Project
                </div>
                <div
                  class="app-dropdown-item dropdown-item p-2"
                  @click="renameProject"
                >
                  Rename Project
                </div>
              </template>
            </AppDropdown>
          </div>
          <div class="mt-4 pl-2">
            <ul class="list-group">
              <li
                v-for="(item, index2) in project.items"
                :key="`item-list-${index}-${index2}`"
                class="list-group-item"
              >
                <div class="row">
                  <router-link
                    class="col-md-10 text-black"
                    tag="div"
                    :to="{
                      name: 'ProductView',
                      params: { id: item.product_id },
                    }"
                  >
                    <div class="row">
                      <div class="col-md-2">
                        <AppMediaViewer
                          :src="item.thumbnail"
                          aspectRatio="1/1"
                          width="50"
                        ></AppMediaViewer>
                      </div>
                      <div
                        class="col-md-10"
                        :class="
                          !item.quantity_name && !item.variant_name
                            ? 'd-flex'
                            : ''
                        "
                      >
                        <div
                          style="font-size: 14px; text-transform: capitalize"
                        >
                          <span v-if="item.quantity_name"
                            >[ {{ item.quantity_name }} ]</span
                          >
                          <span v-if="item.variant_name"
                            >[ {{ item.variant_name }} ]</span
                          >
                        </div>
                        <div class="d-flex align-items-center">
                          {{ item.product_name }} ({{ item.quantity }})
                        </div>
                      </div>
                    </div>
                  </router-link>
                  <div class="col-md-2 d-flex align-items-center">
                    <AppIcon
                      hoverable
                      hoverColor="danger"
                      name="trash_can"
                      class="ml-auto mr-2 mt-3 mt-md-0 ml-md-4 mr-0 mb-md-2"
                      @click="removeProduct(project.project_name, item)"
                    ></AppIcon>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <AppDivider class="mt-4" color="gray-light-4" size="2"></AppDivider>
        </AppCard>
        </template>
        <h2 v-else class="text-center text-uppercase">
          Cart is empty
        </h2>
      </div>
    </div>

    <ActionModal
      v-model="modal.show"
      :header="modal.header"
      :description="modal.description"
      @confirm="modal.onClick"
    ></ActionModal>
  </AppDrawer>
</template>

<script>
import AppDrawer from '@/shared/elements/AppDrawer.vue';
import _appDefaultInput from '@/shared/mixins/_appDefaultInput';
import AppIcon from '@/shared/elements/AppIcon.vue';
import AppMediaViewer from '../../../shared/elements/AppMediaViewer.vue';
import AppDivider from '../../../shared/elements/AppDivider.vue';
import AppCard from '../../../shared/elements/AppCard.vue';
import { mapGetters } from 'vuex';
import AppDropdown from '../../../shared/elements/AppDropdown.vue';
import ActionModal from '../Modals/ActionModal.vue';
import Cart from '../../../shared/api/Cart';
import CartItem from '../../../shared/api/CartItems';

export default {
  name: 'CartDrawer',

  components: {
    AppDrawer,
    AppIcon,
    AppMediaViewer,
    AppDivider,
    AppCard,
    AppDropdown,
    ActionModal,
  },

  mixins: [_appDefaultInput],

  props: {
    value: { type: Boolean, default: false },
  },

  data() {
    return {
      modal: {
        show: false,
        header: '',
        description: '',
        onClick() {},
      },
    };
  },

  computed: {
    ...mapGetters('cart', ['active_cart']),
    ...mapGetters('cart', ['checkout']),
  },

  methods: {
    async fetchCartItems() {
      const response = await Cart.getCart();
      const cartItems = response.data;

      this.$store.dispatch('cart/clearCart');
      const checkoutEmpty = this.checkout.length === 0;
      await cartItems.forEach((project) => {
        if (
          !this.active_cart.find(
            (cart) => cart.project_name === project.project
          )
        ) {
          this.$store.dispatch('cart/newProject', project.project);
        }

        project.items.map((item) => {
          if (item.id && item.product) {
            let product = {
              id: item.id,
              project_name: project.project.replace(/\s/g, '').length
                ? project.project
                : ' ',
              project_id: project.id,
              note: item?.data?.note,
              type: item?.data?.type,
              variant_name: item?.data?.variant_name,
              product_id: item.product_id,
              product_name: item.product.name,
              product_variant_id: item.product_variant_id,
              quantity: item.quantity,
              quantity_option: item.product_quantity_option,
              quantity_name: item.quantity_option,
              thumbnail: this.thumbnail(item.product),
            };
            this.$store.dispatch('cart/addToCart', {
              project: {
                id: project.id,
                name: project.project,
              },
              item: product,
            });

            if (checkoutEmpty && !project.project.replace(/\s/g, '').length) {
              this.$store.dispatch('cart/addToCheckout', product);
            }
          }
        });
      });
    },
    checkoutProject(project) {
      this.modal = {
        show: true,
        header: 'Checkout Project',
        description: 'Click "confirm" to proceed',
        onClick: () => {
          this.$store.dispatch('cart/projectCheckout', project);
          if (this.$route.name === 'OrderCheckout') {
            this.$router.go();
          } else {
            this.$router.push({ name: 'OrderCheckout' });
          }
        },
      };
      // this.modal.show = true;
    },
    duplicateProject() {},
    renameProject() {},
    deleteProject(projectName) {
      this.modal = {
        show: true,
        header: 'Delete Project',
        description: 'Click "confirm" to proceed',
        onClick: () => {
          this.$store.dispatch('cart/removeProject', projectName);
        },
      };
    },
    thumbnail(value) {
      if (value && value?.files?.length > 0) {
        const files = value.files;
        let thumb = value.files.find((file) => file.type === 'primary_image');
        return thumb?.filename || files[0]?.filename;
      } else {
        return null;
      }
    },
    removeProduct(projectName, item) {
      this.modal = {
        show: true,
        header: 'Delete item',
        description: 'Click "confirm" to proceed',
        onClick: () => {
          CartItem.deleteCartItem(item.id).then(() => {
            this.$store.dispatch('cart/removeToCart', {
              project_name: projectName,
              item: item,
            });
            this.$store.dispatch('cart/removeToCheckout', item);
          });
        },
      };
    },
  },
  mounted() {
    this.fetchCartItems();
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/theme/_colors';

.cart-drawer {
  width: 100%;
  height: 100%;

  &-content {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.checkout-icon {
  > svg {
    width: 50px !important;
    height: 50px !important;
  }
}
</style>
