<template>
  <div class="app-dropdown dropdown">
    <div @click.stop="toggleDropdown">
      <slot name="dropdown-button">
        <AppBtn> Dropdown button </AppBtn>
      </slot>
    </div>
    <transition name="app-dropdown-menu">
      <div
        class="dropdown-menu show"
        :class="{ 'dropdown-menu-right': right }"
        v-show="innerVal"
      >
        <slot name="dropdown-menu">
          <component
            v-for="item in items"
            :key="item.id || item.text"
            :is="item.url ? 'router-link' : 'div'"
            class="app-dropdown-item dropdown-item p-2"
            :to="item.url || ''"
            @click.stop="
              $emit('itemClick', item), item.onClick ? item.onClick() : null
            "
          >
            {{ item.text }}
          </component>
        </slot>
      </div>
    </transition>
  </div>
</template>

<script>
import _appDefaultInput from '@/shared/mixins/_appDefaultInput';
import AppBtn from './AppBtn.vue';

export default {
  components: { AppBtn },
  name: 'AppDropdown',

  mixins: [_appDefaultInput],

  props: {
    value: { type: Boolean, default: false },
    right: { type: Boolean, default: false },
    items: {
      type: Array,
      default: () => [{ id: '', text: '', url: '', onClick: () => null }],
    },
  },

  methods: {
    toggleDropdown() {
      this.innerVal = !this.innerVal;
      this.$emit('change', this.innerVal);
    },
  },
};
</script>

<style lang="scss" scoped>
.app-dropdown {
  &-menu {
    &-enter-active,
    &-leave-active {
      transition: opacity 0.1s;
    }
    &-enter,
    &-leave-to {
      opacity: 0;
    }
  }
  &-item {
    cursor: pointer;
  }
}
</style>
