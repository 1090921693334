import axios from 'axios';
import { URL_PREFIX } from './variables';

export const postCartItem = (...rest) =>
    axios.post(`${URL_PREFIX}/cart/items`, ...rest);

export const deleteCartItem = (id, ...rest) =>
    axios.delete(`${URL_PREFIX}/cart/items/${id}`, ...rest);

export default {
    postCartItem,
    deleteCartItem,
};
