<template>
  <!-- Image Files -->
  <img
    v-if="fileSrc && isImage(fileSrc)"
    :src="fileSrc"
    :style="{ 'aspect-ratio': aspectRatio }"
    v-bind="$attrs"
    v-on="$listeners"
  />
  <!-- Video Files -->
  <video
    v-else-if="fileSrc && isVideo(fileSrc)"
    ref="videoFile"
    :src="fileSrc"
    :style="{ 'aspect-ratio': aspectRatio }"
    v-bind="$attrs"
    v-on="$listeners"
  />
  <!-- Placeholder -->
  <img
    v-else
    :src="placeholder"
    :style="{ 'aspect-ratio': aspectRatio }"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import _fileExtensionChecker from '@/shared/mixins/_fileExtensionChecker';
import placeholder from '@/assets/pocketyard_logo_black.svg';
export default {
  name: 'AppMediaViewer',

  mixins: [_fileExtensionChecker],

  props: {
    src: { type: String, default: '' },
    play: { type: Boolean, default: false },
    aspectRatio: { type: String, default: 'initial' },
    files: { type: Array, default: () => [] },
  },

  data() {
    return {
      placeholder,
      videoPlaying: false,
      fileSrc: this.src || null,
    };
  },

  watch: {
    src(src) {
      this.fileSrc = src;
    },
    play(val) {
      if (val) {
        this.$refs.videoFile.play();
      } else {
        this.$refs.videoFile.pause();
      }
    },
  },

  methods: {
    getPrimaryFile() {
      const files = this.files;
      files.forEach((file) => {
        if (file.type === 'primary_image') {
          this.fileSrc = file.filename;
        }
      });
      if (!this.fileSrc) {
        this.fileSrc = files[0].filename;
      }
    },
  },

  mounted() {
    if (this.files.length > 0) {
      this.getPrimaryFile();
    }
  },
};
</script>

<style lang="scss" scoped></style>
